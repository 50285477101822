/** @format */

import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

function Register() {
  const { Register } = useContext(AuthContext);
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleRegister = (e) => {
    e.preventDefault();
    Register(form.name, form.email, form.password);
  };
  const handleFormChanges = function (e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <div className="bg-slate-100 flex items-center justify-center min-h-screen">
        <form action="" className="bg-white p-8 w-1/3 rounded-sm ">
          <h1 className="capitalize text-lg font-semibold border-b pb-2">
            Register
          </h1>
          <label
            htmlFor=""
            className="block text-sm capitalize font-semibold my-2"
          >
            username
          </label>
          <input
            type="text"
            name="name"
            onChange={handleFormChanges}
            value={form.name}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
            placeholder="username"
            id=""
          />
          <label
            htmlFor=""
            className="block text-sm capitalize font-semibold my-2"
          >
            Email*
          </label>
          <input
            type="email"
            name="email"
            onChange={handleFormChanges}
            value={form.email}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
            placeholder="email"
            id=""
          />
          <label
            htmlFor=""
            className="block text-sm capitalize font-semibold my-2"
          >
            password*
          </label>
          <input
            type="password"
            name="password"
            onChange={handleFormChanges}
            value={form.password}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
            placeholder="password"
            id=""
          />

          <button
            className="uppercase w-full bg-black py-2 text-white rounded-md mt-4"
            onClick={handleRegister}
          >
            register
          </button>
          <p className="first-letter:uppercase mt-4 ">
            already have an account?
            <Link
              to={"/"}
              className="first-letter:uppercase text-blue-600 capitalize ms-2"
            >
              sign in
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Register;
