/** @format */

import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from "react-router";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

import Autocomplete from "@mui/material/Autocomplete";
// import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";

// import { port, sizes } from "../../Data";
import { toast } from "react-toastify";
import { FiUploadCloud } from "react-icons/fi";
import { URI } from "../URL";
import axios from "axios";
function AddProduct() {
  //   const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  //   const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [galleryImages, setGalleryImages] = useState(null);
  const [form, setForm] = useState({
    name: "",
    description: "",

    price: 0,
  });

  const addProduct = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");

      console.log(token, "toekn");
      const response = await axios.post(
        `${URI}/api/products`,
        { ...form },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const data = response.data;
      // console.log(response.status, "status");
      if (response.status === 201) {
        setForm({
          name: "",
          description: "",
          price: 0,
        });
      }

      console.log(response);
      console.log(data);
      // Uncomment and use the lines below as needed:
      // setUser(data.user);
      // localStorage.setItem("token", data.token);
      // navigate("/dashboard");
    } catch (err) {
      console.error(
        "Login failed",
        err.response ? err.response.data : err.message
      );
    }
  };
  console.log(form, "form");

  const thumbnailImg = useRef();
  const galleryImgs = useRef();
  //   const dispatch = useDispatch();
  //   useEffect(() => {
  //     dispatch(getBrands());
  //     dispatch(getCategories());
  //   }, []);
  //   const { categories, brands } = useSelector((select) => select.Categories);

  console.log(form);
  const handleChange = (event) => {
    const e = event.target;
    setForm({
      ...form,
      [e.name]: e.value,
    });
  };

  return (
    <div className="px-6 bg-slate-100">
      <div className="flex items-center justify-between w-full">
        <h2 className="capitalize font-semibold text-xl">add product</h2>
        <button
          className="bg-black uppercase  rounded-sm text-white flex gap-1  py-2 px-4 items-center justify-center"
          onClick={() => navigate(-1)}
        >
          <GoArrowLeft />
          <span className="font-semibold text-sm  ">back</span>
        </button>
      </div>

      <form action="" className="w-1/2 mx-auto bg-white p-4 my-8">
        <h1 className="text-xl font-semibold border-b py-4 capitalize">
          product information
        </h1>
        <label
          htmlFor=""
          className="block text-sm capitalize font-semibold my-2"
        >
          Product Name*
        </label>
        <input
          type="text"
          name="name"
          onChange={handleChange}
          value={form.name}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
          placeholder="product name"
          id="title"
        />
        <label
          htmlFor=""
          className="block text-sm capitalize font-semibold my-2"
        >
          description
        </label>
        <textarea
          name="description"
          id="description"
          value={form.description}
          placeholder="product description "
          rows={4}
          onChange={handleChange}
          className="p-2 border rounded-md bg-slate-50 placeholder:capitalize block w-full outline-none "
        ></textarea>

        <div>
          <label
            htmlFor=""
            className="block text-sm capitalize font-semibold my-2"
          >
            price*
          </label>
          <input
            type="number"
            name="price"
            onChange={handleChange}
            value={form.price}
            className="block appearance-none p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
            placeholder="price"
            id=""
          />
        </div>

        <div>
          <label
            htmlFor=""
            className="block text-sm capitalize font-semibold my-2"
          >
            select thumbnail image: *
          </label>
          <input
            type="file"
            name=""
            id=""
            // onChange={(e) => setFile(e.target.files[0])}
            ref={thumbnailImg}
            hidden
          />
          <label
            htmlFor=""
            className="flex items-center justify-start border-blue-200 border w-max p-3 text-base transition-all gap-3  hover:bg-blue-200 hover:shadow-2xl shadow-blue-100 capitalize font-semibold my-2 cursor-pointer "
            // onClick={() => thumbnailImg.current.click()}
          >
            <FiUploadCloud />
            <span>select image</span>
          </label>
        </div>

        <button
          className="w-full bg-black disabled:opacity-50  disabled:cursor-wait text-white py-2 rounded-md uppercase my-2"
          type="submit"
          onClick={addProduct}
          disabled={loading}
        >
          create list
        </button>
      </form>
    </div>
  );
}

export default AddProduct;
