/** @format */

import { GoArrowLeft } from "react-icons/go";
import { useNavigate, useParams } from "react-router";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

import Autocomplete from "@mui/material/Autocomplete";
// import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";

// import { port, sizes } from "../../Data";
import { toast } from "react-toastify";
import { FiUploadCloud } from "react-icons/fi";
import axios from "axios";
import { URI } from "../URL";
function UpdateProduct({}) {
  const { id: productId } = useParams();

  //   const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  //   const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [galleryImages, setGalleryImages] = useState(null);
  const [form, setForm] = useState({
    title: "",
    description: "",

    price: 0,
  });

  const getSingleProduct = async (id) => {
    const token = localStorage.getItem("token");
    console.log(token, "token");
    try {
      setLoading(true);
      const response = await axios.get(
        `${URI}/api/products/${id}/singleproduct`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.status === 200) {
        const data = response.data.product;
        setForm(data);

        setLoading(false);
        // console.log(data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    await updateProduct(productId, form); // Calls the update function with form data
  };
  useEffect(() => {
    getSingleProduct(productId);
  }, [productId]);
  const updateProduct = async (id, form) => {
    const token = localStorage.getItem("token");
    console.log(token, "token");
    try {
      const response = await axios.put(
        `${URI}/api/products/${id}/update`,
        form, // The form data as the request body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        // Optionally, refresh products if needed
        // await getProducts();
      }
      const data = response.data;
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(form);
  const thumbnailImg = useRef();
  const galleryImgs = useRef();
  useEffect(() => {}, []);
  console.log(form);
  const handleChange = (event) => {
    const e = event.target;
    setForm({
      ...form,
      [e.name]: e.value,
    });
  };

  return (
    <>
      {loading ? (
        <div className="font-semibold bg-white text-2xl text-center w-full mt-10 capitalize">
          loading...
        </div>
      ) : (
        <div className="px-6 bg-slate-100">
          <div className="flex items-center justify-between w-full">
            <h2 className="capitalize font-semibold text-xl">add product</h2>
            <button
              className="bg-black uppercase  rounded-sm text-white flex gap-1  py-2 px-4 items-center justify-center"
              onClick={() => navigate(-1)}
            >
              <GoArrowLeft />
              <span className="font-semibold text-sm  ">back</span>
            </button>
          </div>

          <form action="" className="w-1/2 mx-auto bg-white p-4 my-8">
            <h1 className="text-xl font-semibold border-b py-4 capitalize">
              product information
            </h1>
            <label
              htmlFor=""
              className="block text-sm capitalize font-semibold my-2"
            >
              Product Name*
            </label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={form.name}
              className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
              placeholder="product name"
              id="title"
              required
            />
            <label
              htmlFor=""
              className="block text-sm capitalize font-semibold my-2"
            >
              description
            </label>
            <textarea
              name="description"
              id="description"
              value={form.description}
              placeholder="product description "
              rows={4}
              onChange={handleChange}
              required
              className="p-2 border rounded-md bg-slate-50 placeholder:capitalize block w-full outline-none "
            ></textarea>

            <div>
              <label
                htmlFor=""
                className="block text-sm capitalize font-semibold my-2"
              >
                price*
              </label>
              <input
                type="number"
                name="price"
                onChange={handleChange}
                value={form.price}
                className="block appearance-none p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
                placeholder="price"
                required
                id=""
              />
            </div>

            <div>
              <label
                htmlFor=""
                className="block text-sm capitalize font-semibold my-2"
              >
                select thumbnail image: *
              </label>
              <input
                type="file"
                name=""
                id=""
                // onChange={(e) => setFile(e.target.files[0])}
                ref={thumbnailImg}
                hidden
              />
              <label
                htmlFor=""
                className="flex items-center justify-start border-blue-200 border w-max p-3 text-base transition-all gap-3  hover:bg-blue-200 hover:shadow-2xl shadow-blue-100 capitalize font-semibold my-2 cursor-pointer "
                // onClick={() => thumbnailImg.current.click()}
              >
                <FiUploadCloud />
                <span>select image</span>
              </label>
            </div>

            <button
              className="w-full bg-black disabled:opacity-50  disabled:cursor-wait text-white py-2 rounded-md uppercase my-2"
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              update item
            </button>
          </form>
        </div>
      )}
    </>
  );
}

export default UpdateProduct;
