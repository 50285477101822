/** @format */

import { Outlet } from "react-router";

// import TopBar from "../components/TopBar";
import Sidebar from "./SideBar";
import TopBar from "./TopBar";

function LayOut() {
  return (
    <div className="flex ">
      <Sidebar />
      <div className="flex flex-col w-full ms-64">
        <Outlet />
      </div>
    </div>
  );
}

export default LayOut;
