/** @format */

import React from "react";
import { FaRegBell } from "react-icons/fa";
function AlertCenter() {
  return (
    <div>
      <div className="bg-blue-600 py-6 gap-2 px-5 flex items-center justify-start">
        <p className="text-slate-300 text-2xl  ">
          <FaRegBell />
        </p>
        <p className="text-white capitalize font-normal text-2xl ">
          alert center
        </p>
      </div>
      <div className="w-[96%] rounded-md overflow-hidden mx-auto mt-8">
        <div className="bg-blue-600 py-4 px-3 flex items-center justify-between ">
          <p className="capitalize font-medium text-base text-white ">
            recent alerts
          </p>
          <button className="bg-white py-1 px-2 capitalize font-medium text-xs text-black">
            mark all read
          </button>
        </div>
        <div className="flex flex-col gap-3 mt-4 w-[96%] m-auto ">
          {Array.from({ length: 10 }).map((_, i) => (
            <div className="border-l-4 shadow-lg  h-max  rounded-lg p-2 border-l-blue-600">
              <p
                className="flex items-center border-b-2
            pb-2 justify-between "
              >
                <span className="text-blue-700">19 hours ago</span>
                <span className="bg-blue-600 h-2 w-2 rounded-full"></span>
              </p>
              <p className="text-slate-600">
                Monthly closing 1 order(s) status changed from Refunded to
                Payable
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AlertCenter;
