/** @format */

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { URI } from "../URL";

function Orders() {
  const [orders, setOrders] = useState([]);
  const { user, formatTimestamp } = useContext(AuthContext);

  const orderStatuses = ["submitted", "verified", "reviewed", "completed"];
  console.log(orders);
  const getOrders = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${URI}/api/orders/all`, {
        headers: {
          Authorization: token,
        },
      });
      setOrders(response.data);
    } catch (err) {
      console.error(
        "Login failed",
        err.response ? err.response.data : err.message
      );
    }
  };

  const handleStatusChange = async (event, orderId) => {
    const newStatus = event.target.value;
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${URI}/api/orders/${orderId}`,
        { status: newStatus },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.status === 200) {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === orderId ? { ...order, status: newStatus } : order
          )
        );
      }
    } catch (err) {
      console.error(
        "Login failed",
        err.response ? err.response.data : err.message
      );
    }
  };

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <div className="bg-slate-200 px-6">
      <div>
        <h1 className=" capitalize text-xl font-semibold">all orders</h1>
        <h1 className="first-letter:uppercase">
          you have {orders.length} {orders.length > 1 ? "orders" : "order"}
        </h1>
      </div>
      <div className="w-full my-5">
        <div className="flex items-center flex-col justify-between bg-white rounded-sm shadow-slate-300 shadow-md py-4 grow-[2]">
          <div className="flex items-center justify-between w-full mb-4 px-6">
            <p className="font-semibold text-sm">invoices</p>
          </div>
          <div className="flex gap-3 w-full overflow-x-auto flex-col px-2">
            <div className="flex min-w-max gap-4 bg-slate-100 py-2 px-4 items-center justify-between">
              <p className="text-center w-20 capitalize font-semibold">#</p>
              <p className="text-center w-20 capitalize font-semibold">
                order code
              </p>
              <p className="text-center w-20 capitalize font-semibold">agent</p>
              <p className="text-center w-20 capitalize font-semibold">
                total product
              </p>

              <p className="text-center w-20 capitalize font-semibold">
                created at
              </p>

              <p className="text-center w-20 capitalize font-semibold">
                updated at
              </p>

              <p className="text-center w-20 capitalize font-semibold">
                delivery status
              </p>
              <p className="text-center w-28 capitalize font-semibold">
                update status
              </p>
            </div>
            {(user.role === "manager" || user.role === "admin") &&
              orders &&
              orders.map((order, i) => (
                <div
                  className="flex min-w-max gap-4 white items-center justify-between"
                  key={order._id}
                >
                  <p className="text-center w-20">{i + 1}</p>
                  <p className="text-center w-20 capitalize me-2">
                    {order._id}
                  </p>
                  <p className="text-center w-20 capitalize">
                    {order.agent.name}
                  </p>
                  <p className="text-center w-20 capitalize">
                    {order.product.price}
                  </p>
                  <p className="text-center w-20 capitalize">
                    {formatTimestamp(order.createdAt)}
                  </p>
                  <p className="text-center w-20 capitalize">
                    {formatTimestamp(order.updatedAt)}
                  </p>

                  <p className="text-sm py-1 px-2 box-content text-center bg-orange-200 text-orange-500 rounded-2xl w-20 capitalize font-semibold">
                    {order.status}
                  </p>
                  <p className="text-sm box-content text-center rounded-2xl w-28 capitalize font-semibold">
                    <div className="w-full max-w-xs mx-auto my-4">
                      <select
                        id="orderStatus"
                        value={order.status}
                        onChange={(e) => handleStatusChange(e, order._id)}
                        className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md text-gray-700 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >
                        {orderStatuses.map((status, i) => (
                          <option key={i} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </div>
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orders;
